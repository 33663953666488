import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/SEO";
import { Contact } from "../components/Contact";

const DutchContactPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Contact" lang="nl" pathName={location.pathname} />
    <Contact language="nl" />
  </>
);

export default DutchContactPage;
