import React from "react";
import styled from "styled-components";
import { toast } from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";

import { Input, TextArea } from "../Form";
import { PrimaryButton } from "../Buttons";
import LanguageProps from "../../interfaces/LanguageProps";

const CustomerDetails = styled.div`
  display: grid;
  gap: 1rem 0;
  background: ${({ theme }) => theme.colors.grey};
  padding: 2rem 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 2rem;
    gap: 2rem 0;
  }
`;

const Form = styled.form`
  display: grid;
  gap: 2rem 0;
`;

const SubmitButton = styled(PrimaryButton)`
  margin-left: auto;
`;

interface ContactFormProps extends LanguageProps {}

interface ContactFormData {
  locale: string;
  companyName: string;
  name: string;
  emailAddress: string;
  phone: string;
  message: string;
}

const ContactForm = ({ language }: ContactFormProps) => {
  const isEnglish = language === "en";
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const { handleSubmit, control, reset } = useForm<ContactFormData>({
    mode: "onSubmit",
  });

  const onValidSubmit = async (data: ContactFormData, e: any) => {
    setIsSubmitting(true);
    const loadingMessage = isEnglish
      ? "Sending contact form.."
      : "Contactformulier verzenden..";
    const successMessage = isEnglish
      ? "Contact form sent!"
      : "Contactformulier verzonden!";
    const errorMessage = isEnglish
      ? "Something went wrong.."
      : "Er is iets verkeerd gegaan..";

    data.locale = language;

    toast
      .promise(
        axios.post(`${process.env.GATSBY_CMS_BASE_URL}/contact/send`, data),
        {
          loading: loadingMessage,
          success: successMessage,
          error: errorMessage,
        }
      )
      .then(() => {
        e.target.reset();
        reset();
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.error(error);
        setIsSubmitting(false);
      });
  };

  const onInvalidSubmit = async (data: any) => {
    console.log(data);

    const errorMessage = isEnglish
      ? "Please fill in all details"
      : "Vul alstublieft alle gegevens in";

    toast.error(errorMessage);
  };

  return (
    <CustomerDetails>
      <Form onSubmit={handleSubmit(onValidSubmit, onInvalidSubmit)}>
        <Controller
          control={control}
          name="companyName"
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              id="companyName"
              defaultValue=""
              name="companyName"
              type="text"
              label={isEnglish ? "Company Name" : "Bedrijfsnaam"}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="name"
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              id="name"
              name="name"
              type="text"
              defaultValue=""
              label={isEnglish ? "First and Last Name" : "Voor- en Achternaam"}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="emailAddress"
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              id="emailAddress"
              name="emailAddress"
              type="email"
              label={isEnglish ? "E-mail Address" : "E-mailadres"}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="phone"
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <Input
              id="phone"
              name="phone"
              type="phone"
              label={isEnglish ? "Phone" : "Telefoonnummer"}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="message"
          defaultValue=""
          rules={{ required: true }}
          render={({ field }) => (
            <TextArea
              id="message"
              name="message"
              label={isEnglish ? "Message" : "Bericht"}
              rows={6}
              onChange={field.onChange}
            />
          )}
        />
        <SubmitButton type="submit" disabled={isSubmitting}>
          {isEnglish ? "Send" : "Verstuur"}
        </SubmitButton>
      </Form>
    </CustomerDetails>
  );
};

export default ContactForm;
