import React from "react";
import styled from "styled-components";

import { PaddingContainer } from "../../components/Containers";
import { H1 } from "../../components/Text";
import { ContactDetails, ContactForm } from ".";
import LanguageProps from "../../interfaces/LanguageProps";

const InnerContainer = styled.div`
  display: grid;
  gap: 2rem 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface ContactProps extends LanguageProps {}

const Contact = ({ language }: ContactProps) => (
  <>
    <PaddingContainer>
      <H1>Contact</H1>
      <InnerContainer>
        <ContactDetails language={language} />
        <ContactForm language={language} />
      </InnerContainer>
    </PaddingContainer>
  </>
);

export default Contact;
