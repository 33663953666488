import React from "react";
import styled from "styled-components";

import LanguageProps from "../../interfaces/LanguageProps";

const Container = styled.div`
  display: grid;
  gap: 2rem 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    align-self: flex-start;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 2rem 0;
  }
`;

const EmailAndServiceContainer = styled.div`
  display: grid;
  gap: 2rem;
`;

const Section = styled.div`
  display: grid;

  p:first-child {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

const RedParagraph = styled.p`
  color: ${({ theme }) => theme.colors.bavakRed};
  font-weight: bold;
`;

interface ContactDetailsProps extends LanguageProps {}

const ContactDetails = ({ language }: ContactDetailsProps) => {
  const isEnglish = language === "en";

  return (
    <Container>
      <EmailAndServiceContainer>
        <Section>
          <p>E-mail</p>
          <RedParagraph>info@bavak.com</RedParagraph>
        </Section>
        <Section>
          <p>{isEnglish ? "Customer Service" : "Klantenservice"}</p>
          <RedParagraph>+31 71-403 5544</RedParagraph>
        </Section>
      </EmailAndServiceContainer>
      <Section>
        <p>{isEnglish ? "Headquarters" : "Hoofdkantoor"}</p>
        <p>Bavak Security Group BV</p>
        <p>Zwarteweg 19</p>
        <p>2201 AA</p>
        <p>Noordwijk Nederland</p>
      </Section>
      <Section>
        <p>KVK-nummer</p>
        <p>28054318</p>
      </Section>
    </Container>
  );
};

export default ContactDetails;
